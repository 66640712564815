<template id="teams">
  <b-container fluid>
    <b-row class="mt-2 mb-2" align-v="center">
      <b-col>
        <b-form-select
          v-model="filters.opta_cluster"
          :options="opta_clusters"
          value-field="id"
          text-field="name"
        >
        </b-form-select>
      </b-col>
      <b-col>
        <b-form-select
          v-model="filters.opta_league"
          :options="filtered_league"
          value-field="id"
          text-field="name"
        >
          <template #first>
            <b-form-select-option value="null" disabled
              >-- Select league --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
      <b-col>
        <b-input-group prepend="From">
          <b-form-datepicker
            v-model="filters.from"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
          ></b-form-datepicker>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group prepend="To">
          <b-form-datepicker
            v-model="filters.to"
            :dark="true"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
          ></b-form-datepicker>
        </b-input-group>
      </b-col>
      <b-col>
        <b-form-checkbox
          v-model="filters.update_interval"
          :value="1"
          :unchecked-value="0"
        >
          Update league interval
        </b-form-checkbox>
      </b-col>
      <b-col cols="1">
        <b-button @click="getTeamsByLeague"
          ><b-icon icon="search"></b-icon
        ></b-button>
      </b-col>
    </b-row>
    <div v-if="waiting" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <div v-if="!waiting">
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Ranking" active>
            <b-card-text>
              <b-table
                id="table_teams"
                ref="table_teams"
                show-empty
                small
                striped
                dark
                responsive
                hover
                :items="opta_teams"
                :fields="fields"
                sort-by="points"
                :sort-desc="true"
              >
                <template #cell(n)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(logo_url)="data">
                  <b-img :src="data.value" height="30" />
                </template>
                <template #cell(name)="data">
                  <router-link
                    :to="{
                      name: 'ScoutingTeam',
                      params: { id: data.item.id },
                    }"
                    target="_blank"
                    >{{ data.value }}
                  </router-link>
                </template>
                <template #cell(goal_diff)="data">
                  {{ data.item.gf - data.item.ga }}
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
          <b-tab no-body title="Index">
            <b-card-text :key="refreshKey">
              <b-table
                id="table_index"
                ref="table_index"
                show-empty
                small
                striped
                dark
                responsive
                hover
                :items="opta_teams"
                :fields="fields_index"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(n)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(logo_url)="data">
                  <b-img :src="data.value" height="30" />
                </template>
                <template #cell(name)="data">
                  <router-link
                    :to="{
                      name: 'ScoutingTeam',
                      params: { id: data.item.id },
                    }"
                    target="_blank"
                    >{{ data.value }}
                  </router-link>
                </template>
                <template #cell(index_0)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_0]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_1)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_1]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_2)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_2]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_3)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_3]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_4)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_4]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_5)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_5]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_6)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_6]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_7)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_7]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_8)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_8]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_9)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_9]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_10)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_10]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(index_11)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.index_color_rank_11]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(micro_index)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.micro_index_color_rank]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
                <template #cell(macro_index)="data">
                  <Progress
                    :strokeColor="bg_opta[data.item.macro_index_color_rank]"
                    :value="data.value"
                    :radius="25"
                    :strokeWidth="4"
                    :transitionDuration="0"
                  ></Progress>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import loadingImg from "@/assets/images/loading.gif";
import Progress from "easy-circular-progress";
export default {
  components: {
    Progress,
  },

  data: function () {
    return {
      waiting: false,
      filters: {
        opta_league: null,
        opta_cluster: null,
        from: null,
        to: null,
        update_interval: 0,
      },
      opta_teams: [],
      opta_leagues: [],
      opta_clusters: [],
      loadingImg,
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      sortBy: "micro_index",
      sortDesc: true,
      refreshKey: Math.random(),
      fields: [
        {
          key: "n",
          label: "",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
        },
        {
          key: "logo_url",
          label: "",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
        },
        {
          key: "name",
          label: "Team",
          sortable: true,
        },
        {
          key: "games",
          label: "GP",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Games Played",
        },
        {
          key: "points",
          label: "PTS",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Points",
          variant: "success",
        },
        {
          key: "wins",
          label: "W",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Wins",
        },
        {
          key: "draws",
          label: "D",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Draws",
        },
        {
          key: "losses",
          label: "L",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Losses",
        },
        {
          key: "gf",
          label: "GF",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goals For",
        },
        {
          key: "ga",
          label: "GA",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goals Against",
        },
        {
          key: "wins_home",
          label: "WH",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Wins Home",
        },
        {
          key: "draws_home",
          label: "DH",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Draws Home",
        },
        {
          key: "losses_home",
          label: "LH",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Losses Home",
        },
        {
          key: "gf_home",
          label: "GFH",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goals For Home",
        },
        {
          key: "ga_home",
          label: "GAH",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goals Against Home",
        },
        {
          key: "wins_away",
          label: "WA",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Wins Away",
        },
        {
          key: "draws_away",
          label: "DA",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Draws Away",
        },
        {
          key: "losses_away",
          label: "LA",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Losses Away",
        },
        {
          key: "gf_away",
          label: "GFA",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goals For Away",
        },
        {
          key: "ga_away",
          label: "GAA",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goals Against Away",
        },
        {
          key: "goal_diff",
          label: "GD",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Goal Difference",
        },
      ],
      fields_index: [
        {
          key: "n",
          label: "",
          class: "text-center",
          tdClass: "td_lista",
          thStyle: { "min-width": "10px", "max-width": "10px" },
        },
        {
          key: "logo_url",
          label: "",
          class: "text-center",
          tdClass: "td_lista",
          thStyle: { "min-width": "10px", "max-width": "10px" },
        },
        {
          key: "name",
          label: "Team",
          tdClass: "td_lista",
          sortable: true,
        },
        {
          key: "index_0",
          label: "PRE",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "PRESSING",
        },
        {
          key: "index_1",
          label: "REC",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "RECOVERY",
        },
        {
          key: "index_2",
          label: "MAR",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "MARKING",
        },
        {
          key: "index_3",
          label: "BUI",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "BUILD UP",
        },
        {
          key: "index_4",
          label: "POS",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "POSSESSION",
        },
        {
          key: "index_5",
          label: "ENG",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "ENGAGEMENT",
        },
        {
          key: "index_6",
          label: "UNM",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "UNMARKING",
        },
        {
          key: "index_7",
          label: "DRI",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "DRIBBLING",
        },
        {
          key: "index_8",
          label: "WID",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "WIDE PLAY",
        },
        {
          key: "index_9",
          label: "FIN",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "FINISHING",
        },
        {
          key: "index_10",
          label: "CRE",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "CREATIVITY",
        },
        {
          key: "index_11",
          label: "SET",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "SET PLAY",
        },
        {
          key: "micro_index",
          label: "MICRO",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Micro",
        },
        {
          key: "macro_index",
          label: "MACRO",
          class: "text-center",
          thStyle: { "min-width": "10px", "max-width": "10px" },
          sortable: true,
          headerTitle: "Macro",
        },
      ],
    };
  },

  created: function () {
    this.getOptaClusters();
  },

  methods: {
    getOptaClusters() {
      this.$http.get("/opta/clusters").then((response) => {
        this.opta_clusters = response.data;
        if (!this.filters.opta_cluster) {
          this.filters.opta_cluster = 1;
          this.filters.opta_league = 16;
        }
      });
    },

    getTeamsByLeague() {
      this.waiting = true;
      this.$http
        .get("/opta/league/teams", { params: this.filters })
        .then((response) => {
          this.opta_teams = response.data;
          this.waiting = false;
          if (this.filters.update_interval) {
            this.getOptaClusters();
            this.filters.update_interval = 0;
          }
        });
    },
  },

  watch: {
    "filters.opta_league": function () {
      if (this.filters.opta_league) {
        var league = this.opta_clusters
          .find((item) => item.id == this.filters.opta_cluster)
          .leagues.find((item2) => item2.id == this.filters.opta_league);
        this.filters.from = league.from;
        this.filters.to = league.to;
        this.getTeamsByLeague();
      }
    },

    sortBy: function () {
      this.refreshKey = Math.random();
    },

    sortDesc: function () {
      this.refreshKey = Math.random();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
    filtered_league: function () {
      var leagues = [];
      if (this.filters.opta_cluster) {
        leagues = this.opta_clusters.find(
          (item) => item.id == this.filters.opta_cluster
        ).leagues;
      }
      return leagues;
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
